/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { appendQueries, QueryObject } from '../utilities/queries';
import {
  InvoiceSort,
  InvoiceStatusFilter,
  LicenseCategory,
  LockableAction,
  PaymentType,
  PreSetEventValues,
  SortDirection,
  StudentSort,
  TodoOrder,
} from '@tr-types/backend-types';

function url(path: string, queries?: QueryObject) {
  const url = new URL(path, process.env.NEXT_PUBLIC_API_BASE_URL);
  appendQueries(url, queries);
  return url.href;
}

interface StudentOpts {
  // Whether the queried students should only be filtered for ones without any appointment.
  withoutAppointment?: boolean;
  // Whether to include the balance property in the returned student objects.
  // Use carefully, as this is an expensive calculation on the backend.
  includeBalance?: boolean;
  query?: string;
  archived?: boolean;
  sortBy?: StudentSort;
  sortDir?: SortDirection;
  queryNameOnly?: boolean;
  includeNextExamDate?: boolean;
}

interface PaginatedStudentOpts extends StudentOpts {
  skip?: number;
  take?: number;
}

interface StudentCountOpts {
  query?: string;
  archived?: boolean;
}

export interface InvoiceSortingOpts {
  sortBy?: InvoiceSort;
  direction?: SortDirection;
}

interface TimespanOpts {
  // Start of the timespan to search in
  timespanBegin?: string | number;
  // End of the timespan to search in.
  timespanEnd?: string | number;
}

interface VehicleOpts {
  licenseCategory?: LicenseCategory;
  availabilityBegin?: string;
  availabilityEnd?: string;
  // Id of an event that can be ignored when checking the availability
  ignoreEvent?: string;
  query?: string;
  active?: boolean;
}

interface InvoiceOpts {
  status?: InvoiceStatusFilter;
  timespan?: TimespanOpts;
  sort?: InvoiceSortingOpts;
  query?: string;
  paymentEndDate?: string | number;
  includeGroups?: boolean;
  includeLicenseCategories?: boolean;
}

interface UserOpts {
  query?: string;
  archived?: boolean;
}
export class HttpEndpoints {
  static OrganizationEndpoints = {
    getOrganizationById: (orgId: string) =>
      orgId && url(`organizations/${orgId}`),
    getOrganizationLogo: (orgId: string) =>
      orgId && url(`organizations/${orgId}/logo`),
    postOrganizationLogo: (orgId: string) =>
      orgId && url(`organizations/${orgId}/logo`),
    deleteOrganizationLogo: (orgId: string) =>
      orgId && url(`organizations/${orgId}/logo`),
    checkDatransSetup: (orgId: string) =>
      orgId && url(`organizations/${orgId}/datatrans-complete`),
    lessonRequestConfig: (orgId: string) =>
      orgId && url(`organizations/${orgId}/lesson-request-config`),
    patchOrganizationSettings: (orgId: string) =>
      orgId && url(`organizations/${orgId}/org-settings`),
    patchLessonRequestConfig: (orgId: string) =>
      orgId && url(`organizations/${orgId}/lesson-request-config`),
    patchHolidays: (orgId: string) =>
      orgId && url(`organizations/${orgId}/holidays`),
    patchBranding: (orgId: string) =>
      orgId && url(`organizations/${orgId}/branding`),
    patchInvoiceSettings: (orgId: string) =>
      orgId && url(`organizations/${orgId}/invoice-settings`),
    patchPaymentSettings: (orgId: string) =>
      orgId && url(`organizations/${orgId}/payment-settings`),
    patchSmsSettings: (orgId: string) =>
      orgId && url(`organizations/${orgId}/sms-settings`),
  };

  static UserEndpoints = {
    createUser: () => url('users'),
    patchUser: (id: string) => id && url(`users/${id}`),
    patchUserRole: (id: string) => id && url(`users/${id}/role`),
    patchCalendarColor: (id: string) => id && url(`users/${id}/calendar-color`),
    deleteUser: (id: string) => id && url(`users/${id}`),
    getUserById: (id: string) => id && url(`users/${id}`),
    getForSuperior: (id: string) => id && url(`users/${id}/employees`),
    getUsersForOrganization: (orgId: string, opts?: UserOpts) =>
      orgId && url(`organizations/${orgId}/users`, opts as QueryObject),
    getUserBirthdaysForOrg: (orgId: string) =>
      orgId && url(`organizations/${orgId}/users/birthdays`),
    countActiveUsersForOrg: (orgId: string) =>
      orgId && url(`organizations/${orgId}/users/active/count`),
    resetPassword: (
      id: string,
      frontendBaseUrl: string,
      useWelcomeTemplate: boolean,
    ) =>
      id &&
      frontendBaseUrl &&
      url(`users/${id}/reset-password`, {
        frontendBaseUrl,
        useWelcomeTemplate,
      }),
    iCalSubscriptions: {
      getSubscriptionsForUser: (id: string) =>
        id && url(`users/${id}/ical-subscriptions`),
      deleteSubscription: (userId: string, subscriptionId: string) =>
        userId &&
        subscriptionId &&
        url(`users/${userId}/ical-subscriptions/${subscriptionId}`),
      postSubscription: (userId: string) =>
        userId && url(`users/${userId}/ical-subscriptions`),
      getPrivateEventsForUser: (
        userId: string,
        from: string,
        to: string,
        subscriptionIds?: string[],
      ) =>
        userId &&
        url(`users/${userId}/private-events`, { subscriptionIds, from, to }),
    },
    instructorInfosForOrg: (
      orgId: string,
      licenseCategory?: LicenseCategory,
      officeLocationId?: string,
    ) =>
      orgId &&
      url(`organizations/${orgId}/users/instructor-info`, {
        licenseCategory,
        officeLocationId,
      }),
    basicUserInfosForOrg: (orgId: string) =>
      orgId && url(`organizations/${orgId}/users/user-info`),

    calendarExports: {
      postCalendarExport: (userId: string) =>
        userId && url(`users/${userId}/calendar-exports`),
      getCalendarExportsForUser: (userId: string) =>
        userId && url(`users/${userId}/calendar-exports`),
      getCalendarExportById: (id: string, userId: string) =>
        userId && id && url(`users/${userId}/calendar-exports/${id}`),
      patchCalendarExport: (id: string, userId: string) =>
        userId && id && url(`users/${userId}/calendar-exports/${id}`),
      deleteCalendarExport: (id: string, userId: string) =>
        userId && id && url(`users/${userId}/calendar-exports/${id}`),
      calendarSharingUrl: (
        id: string,
        baseUrl: string | undefined,
        language?: 'de' | 'en',
      ) => id && url(`ics/${id}`, { frontendBaseUrl: baseUrl, language }),
    },
  };

  static StudentEndpoints = {
    postStudent: (orgId: string) => url(`organizations/${orgId}/students`),
    createAccount: (id: string, orgId: string, frontendBaseUrl: string) =>
      id &&
      orgId &&
      frontendBaseUrl &&
      url(`organizations/${orgId}/students/${id}/create-account`, {
        frontendBaseUrl,
      }),
    resetPassword: (id: string, orgId: string, frontendBaseUrl: string) =>
      id &&
      orgId &&
      frontendBaseUrl &&
      url(`organizations/${orgId}/students/${id}/reset-password`, {
        frontendBaseUrl,
      }),
    deleteAuth0Account: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/students/${id}/auth0-account`),
    patchStudent: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/students/${id}`),
    deleteStudent: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/students/${id}`),
    getStudentById: (id: string) => id && url(`students/${id}`),
    sendPaymentRequest: (
      id: string,
      orgId: string,
      positionTitle: string,
      positionAmount: number,
    ) =>
      id &&
      orgId &&
      url(`organizations/${orgId}/students/${id}/send-payment-request`, {
        positionTitle,
        positionAmount,
      }),
    getStudentsForOrganization: (orgId: string, opts?: PaginatedStudentOpts) =>
      orgId && url(`organizations/${orgId}/students`, opts && { ...opts }),
    getStudentCountForOrganization: (orgId: string, opts?: StudentCountOpts) =>
      orgId &&
      url(`organizations/${orgId}/students/count`, opts && { ...opts }),
    getStudentCountForInstructor: (insId: string, opts?: StudentCountOpts) =>
      insId && url(`users/${insId}/students/count`, opts && { ...opts }),
    getStudentsForInstructor: (insId: string, opts?: PaginatedStudentOpts) =>
      insId && url(`users/${insId}/students`, opts && { ...opts }),
    postFileAttachment: (id: string, orgId: string, fileName: string) =>
      id &&
      orgId &&
      url(`organizations/${orgId}/students/${id}/file-attachments/${fileName}`),
    deleteFileAttachment: (id: string, orgId: string, fileName: string) =>
      id &&
      orgId &&
      url(`organizations/${orgId}/students/${id}/file-attachments/${fileName}`),
    getFileAttachment: (id: string, orgId: string, fileName: string) =>
      id &&
      orgId &&
      url(`organizations/${orgId}/students/${id}/file-attachments/${fileName}`),
    getFileAttachmentSignedUrl: (id: string, orgId: string, fileName: string) =>
      id &&
      orgId &&
      url(
        `organizations/${orgId}/students/${id}/file-attachments/${fileName}/url`,
      ),
  };

  static StudentGroupEndpoints = {
    postStudentGroup: (orgId: string) =>
      url(`organizations/${orgId}/student-groups`),
    patchStudentGroup: (id: string, orgId: string) =>
      id && url(`organizations/${orgId}/student-groups/${id}`),
    deleteStudentGroup: (id: string, orgId: string) =>
      id && url(`organizations/${orgId}/student-groups/${id}`),
    getStudentGroupById: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/student-groups/${id}`),
    getStudentGroupsForOrganization: (orgId: string) =>
      orgId && url(`organizations/${orgId}/student-groups`),
  };

  static ExamEndpoints = {
    getExamsForStudent: (
      studentId: string,
      orgId: string,
      licenseCategory?: LicenseCategory,
    ) =>
      studentId &&
      orgId &&
      url(
        `organizations/${orgId}/students/${studentId}/exams`,
        licenseCategory && { licenseCategory },
      ),
    getExamAttemptsByExam: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/exams/${id}/exam-attempts`),
    getExamById: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/exams/${id}`),
    postExam: (orgId: string) => orgId && url(`organizations/${orgId}/exams`),
    patchExam: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/exams/${id}`),
    deleteExam: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/exams/${id}`),
  };

  static ExamAttemptEndpoints = {
    getExamAttemptById: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/exam-attempts/${id}`),
    postExamAttempt: (orgId: string) =>
      orgId && url(`organizations/${orgId}/exam-attempts`),
    patchExamAttempt: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/exam-attempts/${id}`),
    deleteExamAttempt: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/exam-attempts/${id}`),
    getForInstructor: (
      id: string,
      sortOptions?: { sortBy?: string; sortDir?: SortDirection },
      year?: string | number,
    ) => id && url(`users/${id}/exam-attempts`, { year, ...sortOptions }),
  };

  static WorkEventEndpoints = {
    findForOrganization: (id: string, timespan?: TimespanOpts) =>
      id &&
      url(`organizations/${id}/work-events`, {
        from: timespan?.timespanBegin,
        to: timespan?.timespanEnd,
      }),
    countForOrganization: (id: string, timespan?: TimespanOpts) =>
      id &&
      url(`organizations/${id}/work-events/count`, {
        from: timespan?.timespanBegin,
        to: timespan?.timespanEnd,
      }),
    postWorkEvent: (userId: string) => url(`users/${userId}/work-events`),
    patchWorkEvent: (id: string, userId: string) =>
      id && userId && url(`users/${userId}/work-events/${id}`),
    deleteWorkEvent: (id: string, userId: string) =>
      id && userId && url(`users/${userId}/work-events/${id}`),
    getWorkEventById: (id: string | undefined, userId: string | undefined) =>
      id && userId && url(`users/${userId}/work-events/${id}`),
    // this endpoint doesn't check access rights - only use in contextx where access rights are not important
    getWorkEventByIdWithoutUser: (id: string) => id && url(`work-events/${id}`),
    getWorkEventsForUser: (userId: string, timespan?: TimespanOpts) =>
      userId &&
      url(`users/${userId}/work-events`, {
        from: timespan?.timespanBegin,
        to: timespan?.timespanEnd,
      }),
    getWorkEventsForUsers: (
      orgId: string,
      ids: string[],
      timespan?: TimespanOpts,
    ) =>
      orgId &&
      url(`organizations/${orgId}/multi-user-work-events`, {
        from: timespan?.timespanBegin,
        to: timespan?.timespanEnd,
        ids,
      }),
    countWorkEventsForUser: (userId: string, timespan?: TimespanOpts) =>
      userId &&
      url(`users/${userId}/work-events/count`, {
        from: timespan?.timespanBegin,
        to: timespan?.timespanEnd,
      }),
    getWorkEventsForStudent: (
      id: string,
      orgId: string,
      licenseCategory?: string,
    ) =>
      id &&
      orgId &&
      url(`organizations/${orgId}/students/${id}/work-events`, {
        licenseCategory,
      }),
    getNextExamEventForStudent: (id: string, orgId: string) =>
      id &&
      orgId &&
      url(`organizations/${orgId}/students/${id}/next-exam-event`),
    getEventDefaultValuesForStudent: (
      id: string,
      orgId: string,
      filledOutValues: PreSetEventValues,
    ) =>
      id &&
      orgId &&
      url(
        `organizations/${orgId}/students/${id}/work-events/event-default-values`,
        filledOutValues && {
          ...filledOutValues,
        },
      ),
    getWorkEventsByVehicle: (
      vId: string,
      orgId: string,
      timespan?: TimespanOpts,
    ) =>
      vId &&
      orgId &&
      url(`organizations/${orgId}/vehicles/${vId}/work-events`, {
        timestampBegin: timespan?.timespanBegin,
        timestampEnd: timespan?.timespanEnd,
      }),
  };

  static VacationEventEndpoints = {
    postVacationEvent: (userId: string) =>
      url(`users/${userId}/vacation-entries`),
    patchVacationEvent: (id: string, userId: string) =>
      id && userId && url(`users/${userId}/vacation-entries/${id}`),
    deleteVacationEvent: (id: string, userId: string) =>
      id && userId && url(`users/${userId}/vacation-entries/${id}`),
    getVacationEventById: (id: string, userId: string) =>
      id && userId && url(`users/${userId}/vacation-entries/${id}`),
    getVacationEventsByOrganization: (orgId: string, timespan?: TimespanOpts) =>
      orgId &&
      url(`organizations/${orgId}/vacation-entries`, {
        from: timespan?.timespanBegin,
        to: timespan?.timespanEnd,
      }),
    getVacationEventsForUser: (id: string, timespan?: TimespanOpts) =>
      id &&
      url(`users/${id}/vacation-events`, {
        from: timespan?.timespanBegin,
        to: timespan?.timespanEnd,
      }),
    getVacationEventsForUsers: (
      orgId: string,
      ids: string[],
      timespan?: TimespanOpts,
    ) =>
      orgId &&
      url(`organizations/${orgId}/multi-user-vacation-events`, {
        from: timespan?.timespanBegin,
        to: timespan?.timespanEnd,
        ids,
      }),
  };

  static CalendarEntryEndpoints = {
    getCalendarEntryById: (id: string) => url(`calendar-entries/${id}`),
  };

  static BillingTypeEndpoints = {
    postBillingType: () => url('billing-types'),
    deleteBillingType: (id: string) => id && url(`billing-types/${id}`),
    patchBillingType: (id: string, includeHistory?: boolean) =>
      id && url(`billing-types/${id}`, { includeHistory }),
    getBillingTypeById: (id: string, opts?: { includeHistory?: boolean }) =>
      id && url(`billing-types/${id}`, opts),
    getBillingTypesByOrganization: (
      orgId: string,
      opts?: {
        query?: string;
        activeOn?: string;
        archivedOn?: string;
        licenseCategory?: string;
      },
    ) => orgId && url(`organizations/${orgId}/billing-types`, opts),
  };

  static BillingTypeTagEndpoints = {
    postBillingTypeTag: (orgId: string) =>
      orgId && url(`organizations/${orgId}/billing-type-tags`),
    patchBillingTypeTag: (id: string, orgId: string) =>
      orgId && id && url(`organizations/${orgId}/billing-type-tags/${id}`),
    deleteBillingTypeTag: (id: string, orgId: string) =>
      orgId && id && url(`organizations/${orgId}/billing-type-tags/${id}`),
    getBillingTypeTagById: (id: string, orgId: string) =>
      orgId && id && url(`organizations/${orgId}/billing-type-tags/${id}`),
    getBillingTypeTagsForOrganization: (orgId: string) =>
      orgId && url(`organizations/${orgId}/billing-type-tags`),
  };

  static SyllabusEndpoints = {
    postSyllabus: (orgId: string) =>
      orgId && url(`organizations/${orgId}/syllabus`),
    patchSyllabus: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/syllabus/${id}`),
    duplicateSyllabus: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/syllabus/${id}/duplicate`),
    deleteSyllabus: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/syllabus/${id}`),
    getSyllabusById: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/syllabus/${id}`),
    getSyllabiByOrganization: (
      orgId: string,
      query?: string,
      archived?: boolean,
    ) => orgId && url(`organizations/${orgId}/syllabus`, { query, archived }),
  };

  static LessonEndpoints = {
    postLesson: () => url('lessons'),
    patchLesson: (id: string) => id && url(`lessons/${id}`),
    deleteLesson: (id: string) => id && url(`lessons/${id}`),
    getLessonbyId: (id: string) => id && url(`lessons/${id}`),
    getLessonsBySyllabus: (id?: string, orgId?: string) =>
      id && orgId && url(`organizations/${orgId}/syllabus/${id}/lessons`),
    getFileAttachment: (id: string, fileName: string) =>
      id && fileName && url(`lessons/${id}/file-attachments/${fileName}`),
    getFileAttachmentSignedUrl: (id: string, fileName: string) =>
      id && fileName && url(`lessons/${id}/file-attachments/${fileName}/url`),
    deleteFileAttachment: (id: string, fileName: string) =>
      id && fileName && url(`lessons/${id}/file-attachments/${fileName}`),
    postFileAttachment: (id: string, fileName: string) =>
      id && fileName && url(`lessons/${id}/file-attachments/${fileName}`),
  };

  static ProgressCardEndpoints = {
    postProgressCard: () => url(`syllabus-progress-cards`),
    deleteProgressCard: (id: string) =>
      id && url(`syllabus-progress-cards/${id}`),
    getProgressCardById: (id: string) =>
      id && url(`syllabus-progress-cards/${id}`),
    getProgressCardsByStudent: (id: string, orgId: string) =>
      id &&
      orgId &&
      url(`organizations/${orgId}/students/${id}/progress-cards`),
    getIncompleteCards: (instructorId?: string, organizationId?: string) =>
      (instructorId || organizationId) &&
      url(`syllabus-progress-cards/incomplete`, {
        instructorId,
        organizationId,
      }),
  };

  static ItemScoreEndpoints = {
    postItemScore: () => url(`syllabus-item-scores`),
    patchItemScore: () => url(`syllabus-item-scores`),
    deleteItemScore: () => url(`syllabus-item-scores`),
    getItemScoresByCard: (id: string) =>
      id && url(`syllabus-progress-cards/${id}/syllabus-item-scores`),
  };

  static VehicleEndpoints = {
    postVehicle: (orgId: string) =>
      orgId && url(`organizations/${orgId}/vehicles`),
    patchVehicle: (vId: string, orgId: string) =>
      vId && orgId && url(`organizations/${orgId}/vehicles/${vId}`),
    deleteVehicle: (vId: string, orgId: string) =>
      vId && orgId && url(`organizations/${orgId}/vehicles/${vId}`),
    getVehicleById: (vId: string, orgId: string) =>
      vId && orgId && url(`organizations/${orgId}/vehicles/${vId}`),
    getVehiclesForOrganization: (orgId: string, opts?: VehicleOpts) =>
      orgId &&
      url(
        `organizations/${orgId}/vehicles`,
        opts && {
          ...opts,
        },
      ),
    countVehiclesForOrg: (orgId: string) =>
      orgId && url(`organizations/${orgId}/vehicles/count`),
    getVehiclesForInstructor: (insId: string, opts?: VehicleOpts) =>
      insId &&
      url(
        `users/${insId}/vehicles`,
        opts && {
          ...opts,
        },
      ),
  };

  static TimesheetEndpoints = {
    postTimesheet: (userId: string) => url(`users/${userId}/timesheets`),
    patchTimesheet: (id: string, userId: string) =>
      url(`users/${userId}/timesheets/${id}`),
    getTimesheetsForUser: (id: string, year?: string) =>
      id && url(`users/${id}/timesheets`, { year }),
    getVacationHoursForUser: (id: string, from: string, until: string) =>
      id && url(`users/${id}/vacation-hours`, { from, until }),
    getTimesheetsForOrg: (orgId: string, year: string | number) =>
      orgId &&
      (year != null || '') &&
      url(`organizations/${orgId}/timesheets`, { year }),
    getBillingTypeVolumeForUser: (id: string, start: string, end: string) =>
      id && url(`users/${id}/billing-type-work-time`, { start, end }),
    getBillingTypeVolumeForOrganization: (
      id: string,
      start: string,
      end: string,
    ) =>
      id && url(`organizations/${id}/billing-type-work-time`, { start, end }),
    getBalancesForOrganization: (id: string, date: string) =>
      id && url(`organizations/${id}/timesheets/balances`, { date }),
  };

  static ProductEndpoints = {
    postProduct: (orgId: string) =>
      orgId && url(`organizations/${orgId}/products`),
    patchProduct: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/products/${id}`),
    deleteProduct: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/products/${id}`),
    getProductById: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/products/${id}`),
    getProductsForOrganization: (orgId: string, query?: string) =>
      orgId && url(`organizations/${orgId}/products`, { query }),
  };

  static PaymentEndpoints = {
    postPayment: (orgId: string) => url(`organizations/${orgId}/payments`),
    createPaymentsFromBankStatement: (
      orgId: string,
      ignoreDuplicates?: boolean,
    ) =>
      url(`organizations/${orgId}/payments/bank-statement`, {
        ignoreDuplicates,
      }),
    deletePaymentById: (id: string, orgId: string) =>
      url(`organizations/${orgId}/payments/${id}`),
    getPaymentById: (id: string, orgId: string) =>
      orgId && url(`organizations/${orgId}/payments/${id}`),
    getPaymentsForOrganization: (
      orgId: string,
      filters?: {
        paymentType?: PaymentType;
        studentGroupIds?: string[];
        query?: string;
        skip?: number;
        take?: number;
        from?: string;
        to?: string;
      },
    ) => orgId && url(`organizations/${orgId}/payments`, filters),
    getPaymentCountForOrganization: (
      orgId: string,
      filters?: {
        paymentType?: PaymentType;
        studentGroupIds?: string[];
        query?: string;
        from?: string;
        to?: string;
      },
    ) => orgId && url(`organizations/${orgId}/payments/count`, filters),
    getPaymentSumForOrganization: (
      orgId: string,
      filters?: {
        paymentType?: PaymentType;
        studentGroupIds?: string[];
        query?: string;
        from?: string;
        to?: string;
      },
    ) => orgId && url(`organizations/${orgId}/payments/sum`, filters),

    getTransactionId: (
      studentId: string,
      orgId: string,
      amountCents: number,
      successUrl: string,
      errorUrl: string,
      cancelUrl: string,
    ) =>
      url(
        `organizations/${orgId}/students/${studentId}/payments/datatrans-payment`,
        {
          amountCents,
          successUrl,
          errorUrl,
          cancelUrl,
        },
      ),
    getPaymentsForStudent: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/students/${id}/payments`),
  };
  static PaymentRequestHistoryEndpoints = {
    getHistoryEntriesForStudent: (id: string) =>
      id && url(`students/${id}/payment-request-history-entries`),
    getPDF: (studentId: string, id: string) =>
      id &&
      studentId &&
      url(`students/${studentId}/payment-request-history-entries/${id}/pdf`),
  };

  static InvoiceEndpoints = {
    postInvoice: () => url('invoices'),
    patchInvoice: (id: string) => id && url(`invoices/${id}`),
    sendInvoiceMail: (id: string) => id && url(`invoices/${id}/send`),
    deleteInvoice: (id: string) => id && url(`invoices/${id}`),
    getInvoiceById: (id: string) => id && url(`invoices/${id}`),
    getInvoicePdfById: (id: string, percentage?: number, date?: string) =>
      id && url(`invoices/${id}/pdf`, { percentage, date }),
    getInvoicesForOrganization: (orgId: string, opts?: InvoiceOpts) =>
      orgId &&
      url(`organizations/${orgId}/invoices`, {
        status: opts?.status,
        searchPeriodStart: opts?.timespan?.timespanBegin,
        searchPeriodEnd: opts?.timespan?.timespanEnd,
        sortBy: opts?.sort?.sortBy,
        sortDirection: opts?.sort?.direction,
        query: opts?.query,
        paymentEndDate: opts?.paymentEndDate,
        includeGroups: opts?.includeGroups,
        includeLicenseCategories: opts?.includeLicenseCategories,
      }),
    getPendingInvoicesForOrganization: (orgId: string, opts?: InvoiceOpts) =>
      orgId &&
      url(`organizations/${orgId}/pending-invoices`, {
        start: opts?.timespan?.timespanBegin,
        end: opts?.timespan?.timespanEnd,
        sortBy: opts?.sort?.sortBy,
        sortDirection: opts?.sort?.direction,
        includeGroups: opts?.includeGroups,
        includeLicenseCategories: opts?.includeLicenseCategories,
      }),
    getInvoicesForStudent: (id: string, orgId: string, opts?: InvoiceOpts) =>
      orgId &&
      id &&
      url(`organizations/${orgId}/students/${id}/invoices`, {
        status: opts?.status,
        searchPeriodStart: opts?.timespan?.timespanBegin,
        searchPeriodEnd: opts?.timespan?.timespanEnd,
        sortBy: opts?.sort?.sortBy,
        sortDirection: opts?.sort?.direction,
      }),
    getPendingInvoiceForStudent: (
      id: string,
      orgId: string,
      timespan?: TimespanOpts,
    ) =>
      id &&
      orgId &&
      url(`organizations/${orgId}/students/${id}/pending-invoice`, {
        from: timespan?.timespanBegin,
        to: timespan?.timespanEnd,
      }),
  };

  static InvoicePositionEndpoints = {
    postInvoicePosition: () => url('invoice-positions'),
    patchInvoicePosition: (id: string) => id && url(`invoice-positions/${id}`),
    deleteInvoicePosition: (id: string) => id && url(`invoice-positions/${id}`),
  };

  static InvoiceTypeEndpoints = {
    postInvoicetype: (orgId: string) =>
      orgId && url(`organizations/${orgId}/invoice-types`),
    patchInvoiceType: (orgId: string, typeId: string) =>
      orgId && url(`organizations/${orgId}/invoice-types/${typeId}`),
    deleteInvoiceType: (orgId: string, typeId: string) =>
      orgId && typeId && url(`organizations/${orgId}/invoice-types/${typeId}`),
    getInvoiceTypesByOrganization: (orgId: string) =>
      orgId && url(`organizations/${orgId}/invoice-types`),
    getInvoiceTypeById: (orgId: string, typeId: string) =>
      orgId && typeId && url(`organizations/${orgId}/invoice-types/${typeId}`),
  };

  static InvoiceHistoryEndpoints = {
    postInvoiceHistoryItem: () => url('invoice-history-items'),
  };

  static MeetingPointEndpoints = {
    postMeetingPoint: (orgId: string) =>
      orgId && url(`organizations/${orgId}/meeting-points`),
    patchMeetingPoint: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/meeting-points/${id}`),
    deleteMeetingPoint: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/meeting-points/${id}`),
    getMeetingPointById: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/meeting-points/${id}`),
    getMeetingPointsByOrganization: (orgId: string) =>
      orgId && url(`organizations/${orgId}/meeting-points`),
  };

  static DropOutReasonsEndpoints = {
    postDropOutReason: (orgId: string) =>
      orgId && url(`organizations/${orgId}/drop-out-reasons`),
    patchDropOutReason: (orgId: string, id: string) =>
      orgId && id && url(`organizations/${orgId}/drop-out-reasons/${id}`),
    getDropOutReasonById: (orgId: string, id: string) =>
      orgId && id && url(`organizations/${orgId}/drop-out-reasons/${id}`),
    deleteDropOutReason: (orgId: string, id: string) =>
      orgId && id && url(`organizations/${orgId}/drop-out-reasons/${id}`),
    getDropoutReasonsByOrganization: (orgId: string) =>
      orgId && url(`organizations/${orgId}/drop-out-reasons`),
  };

  static DropOutItemsEndpoints = {
    postDropOutItem: (orgId: string) =>
      orgId && url(`organizations/${orgId}/drop-out-items`),
    patchDropOutItem: (orgId: string, id: string) =>
      orgId && id && url(`organizations/${orgId}/drop-out-items/${id}`),
    findForInstructor: (instructorId: string, year?: string | number) =>
      instructorId && url(`users/${instructorId}/drop-out-items`, { year }),
    deleteDropOutItem: (orgId: string, id: string) =>
      orgId && id && url(`organizations/${orgId}/drop-out-items/${id}`),
  };

  static PaymentShortcutsEndpoints = {
    getPaymentShortcutsForOrg: (orgId: string) =>
      orgId && url(`organizations/${orgId}/payment-shortcuts`),
    postPaymentShortcut: (orgId: string) =>
      orgId && url(`organizations/${orgId}/payment-shortcuts`),
    patchPaymentShortcut: (orgId: string, id: string) =>
      orgId && id && url(`organizations/${orgId}/payment-shortcuts/${id}`),
    deletePaymentShortcut: (orgId: string, id: string) =>
      orgId && id && url(`organizations/${orgId}/payment-shortcuts/${id}`),
    getPaymentShortcutById: (orgId: string, id: string) =>
      orgId && id && url(`organizations/${orgId}/payment-shortcuts/${id}`),
  };

  static PaymentRequestShortcutsEndpoints = {
    getPaymentShortcutsForOrg: (orgId: string) =>
      orgId && url(`organizations/${orgId}/payment-request-shortcuts`),
    postPaymentShortcut: (orgId: string) =>
      orgId && url(`organizations/${orgId}/payment-request-shortcuts`),
    patchPaymentShortcut: (orgId: string, id: string) =>
      orgId &&
      id &&
      url(`organizations/${orgId}/payment-request-shortcuts/${id}`),
    deletePaymentShortcut: (orgId: string, id: string) =>
      orgId &&
      id &&
      url(`organizations/${orgId}/payment-request-shortcuts/${id}`),
    getPaymentShortcutById: (orgId: string, id: string) =>
      orgId &&
      id &&
      url(`organizations/${orgId}/payment-request-shortcuts/${id}`),
  };

  static WorkEventAuditLog = {
    getAuditLogEntriesForOrganization: (orgId: string) =>
      orgId && url(`organizations/${orgId}/audit-log-entries`),
    getAuditLogEntriesForActor: (orgId: string, userId: string) =>
      orgId &&
      userId &&
      url(`organizations/${orgId}/audit-log-entries/forActor/${userId}`),
  };

  static LockPeriods = {
    postLockPeriod: (orgId: string) =>
      orgId && url(`organizations/${orgId}/lock-periods`),
    findForOrganization: (orgId: string, year?: number) =>
      orgId && url(`organizations/${orgId}/lock-periods`, { year }),
    deleteLockPeriods: (
      orgId: string,
      year: number,
      month: number,
      action: LockableAction,
    ) => url(`organizations/${orgId}/lock-periods/${year}/${month}/${action}`),
    check: (
      orgId: string,
      start: string,
      end: string,
      action: LockableAction,
    ) =>
      orgId &&
      start &&
      end &&
      action &&
      url(`organizations/${orgId}/lock-periods/check`, {
        start,
        end,
        action,
      }),
  };

  static ExaminerEndpoints = {
    postExaminer: (orgId: string) =>
      orgId && url(`organizations/${orgId}/examiners`),
    deleteExaminer: (orgId: string, id: string) =>
      orgId && id && url(`organizations/${orgId}/examiners/${id}`),
    getByOrganization: (orgId: string, query?: string) =>
      orgId && url(`organizations/${orgId}/examiners`, { query }),
  };

  static AppUsageContractEndpoints = {
    getActiveForOrganization: (orgId: string | undefined) =>
      orgId && url(`organizations/${orgId}/app-usage-contracts/active`),
  };

  static OfficeLocationsEndpoints = {
    postOfficeLocation: (orgId: string) =>
      orgId && url(`organizations/${orgId}/office-locations`),
    patchOfficeLocation: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/office-locations/${id}`),
    deleteOfficeLocation: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/office-locations/${id}`),
    getOfficeLocationById: (id: string, orgId: string) =>
      id && orgId && url(`organizations/${orgId}/office-locations/${id}`),
    getOfficeLocationsByOrganization: (orgId: string) =>
      orgId && url(`organizations/${orgId}/office-locations`),
  };

  static LessonRequestEndpoints = {
    getLessonRequest: (
      id: string | undefined,
      organizationId: string | undefined,
    ) =>
      id &&
      organizationId &&
      url(`organizations/${organizationId}/lesson-requests/${id}`),
    patch: (id: string, organizationId: string) =>
      url(`organizations/${organizationId}/lesson-requests/${id}`),
    getByOrg: (
      orgId: string,
      opts?: { completed?: boolean; unassignedOnly?: boolean },
    ) => orgId && url(`organizations/${orgId}/lesson-requests`, opts),
    getByInstructor: (userId: string, opts?: { completed?: boolean }) =>
      userId && url(`users/${userId}/lesson-requests`, opts),
    create: (orgId: string) =>
      orgId && url(`organizations/${orgId}/lesson-requests`),
  };

  static LessonRequestHistoryEndpoints = {
    post: () => url('lesson-request-assignment-history'),
  };

  static TodoEndpoints = {
    _endpointBase: () => url('todos'),
    post: () => url('todos'),
    patch: (id: string) => id && url(`todos/${id}`),
    updateStatus: (id: string) => id && url(`todos/${id}/status`),
    delete: (id: string) => id && url(`todos/${id}`),
    get: (id: string) => id && url(`todos/${id}`),
    getForAssignedUser: (
      userId: string,
      completed?: boolean,
      completedFrom?: string,
      completedTo?: string,
    ) =>
      userId &&
      url(`todos/byAssignedUser/${userId}`, {
        completed,
        completedFrom,
        completedTo,
      }),
    getForAuthor: (
      userId: string,
      options?: {
        completed?: boolean;
        completedFrom?: string;
        completedTo?: string;
        includeAssignedToAuthor?: boolean;
        orderBy?: TodoOrder;
        order?: 'ASC' | 'DESC';
      },
    ) => userId && url(`todos/byAuthor/${userId}`, options),
    countForAssignedUser: (userId: string, completed?: boolean) =>
      userId && url(`todos/byAssignedUser/${userId}/count`, { completed }),
  };

  static RoleEndpoints = {
    getRolesForOrganization: (orgId: string) =>
      orgId && url(`organizations/${orgId}/roles`),
    postRole: (orgId: string) => orgId && url(`organizations/${orgId}/roles`),
    patchRole: (orgId: string, roleId: string) =>
      orgId && roleId && url(`organizations/${orgId}/roles/${roleId}`),
    deleteRole: (orgId: string, roleId: string) =>
      orgId && roleId && url(`organizations/${orgId}/roles/${roleId}`),
    getRoleById: (orgId: string, roleId: string) =>
      orgId && roleId && url(`organizations/${orgId}/roles/${roleId}`),
  };
}
